export const cidadeGold01 = {
    name: 'La Bonita',
    sobrenome: 'Bar e Restaurante',
    // logo: require('../../images/logos/la_bonita_logo.png'),
    fachada: require('../../images/fachadas/la_bonita_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/8uSL7nYwP3AHJxEs7',
    link2: '',
    link3: 'https://instagram.com/labonitabarerestaurante',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeGold02 = {
    name: 'Vuoi',
    sobrenome: '',
    // logo: require('../../images/logos/vuoi_logo.png'),
    fachada: require('../../images/fachadas/vuoi_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/ja3AD6v7DQ91Hwfu6?g_st=ic',
    link2: 'https://api.whatsapp.com/send?phone=5567991889585&text=Ol%C3%A1,%20cheguei%20aqui%20pelo%20QR%20Maps.',
    link3: 'https://www.instagram.com/vuoitrattoria?igsh=MW5neDA5aHVtdTg0Yw==',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}


export const cidadeGold03 = {
    name: 'Pastel Bonito',
    sobrenome: '',
    // logo: require('../../images/logos/vuoi_logo.png'),
    fachada: require('../../images/fachadas/pastel_fachada_b.jpg'),
    link1: 'https://maps.app.goo.gl/YxHYtsdhQWyKuMoe6',
    link2: 'https://wa.me/5567984377037?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://instagram.com/pastelbonito',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeGold04 = {
    name: 'Agência Ygarapé',
    sobrenome: '',
    // logo: require('../../images/logos/vuoi_logo.png'),
    fachada: require('../../images/fachadas/ygarape_fachada.jpg'),
    link1: 'https://goo.gl/maps/d9o2ZBf8vh2SEbv18',
    link2: 'https://wa.me/message/IUXORLZE5ZG6H1',
    link3: 'https://www.instagram.com/agenciaygarape',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeGold05 = {
    name: 'Brasa Bonito',
    sobrenome: '',
    // logo: require('../../images/logos/vuoi_logo.png'),
    fachada: require('../../images/fachadas/brasa_fachada_b.jpg'),
    link1: 'https://maps.app.goo.gl/SSqafhJ9eowGkhyE9'    ,
    link2: 'https://wa.me/5567984619539?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/brasabonito/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeGold06 = {
    name: 'Varandas Restaurante',
    sobrenome: '',
    logo: require('../../images/logos/varandas_logo.png'),
    fachada: require('../../images/fachadas/varandas_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/9mbuDcfwTMR24ZFc8',
    link2: '',
    link3: 'https://www.instagram.com/varandasbonitoms',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeGold07 = {
    name: 'Bacuri Cozinha Regional',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/bacuri_fachada_b.jpg'),
    link1: 'https://maps.app.goo.gl/3HNAzoqQ3spbghju6',
    link2: 'https://bacuricozinharegional.com.br',
    link3: 'https://www.instagram.com/bacuricozinharegional',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}


export const cidadeNormal08 = {
    name: 'Abaetecotur',
    sobrenome: '',
    logo: require('../../images/logos/abaetecotur_logo.png'),
    fachada: require('../../images/fachadas/abaetecotour_fachada.jpg'),
    link1: 'https://www.google.com/maps/place/Abaetecotur+-+Agência+de+Turismo/@-21.1224097,-56.4989533,17z/data=!3m1!4b1!4m5!3m4!1s0x947c5f4825f8f5d7:0xba7d978dc0c3fa05!8m2!3d-21.1224511!4d-56.496789',
    link2: 'http://www.abaetecotur.com.br/',
    link3: 'https://instagram.com/bonito_abaetecotur',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal09 = {
    name: 'Atual Mini Mercado',
    sobrenome: '',
    logo: require('../../images/logos/atual_logo.png'),
    fachada: require('../../images/fachadas/atual_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/YkziUdakAtX6JPuH7',
    link2: 'http://wa.me/5567992171676',
    link3: 'https://www.instagram.com/atualminimercado/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal10 = {
    name: 'Academia Power Fit',
    sobrenome: '',
    logo: require('../../images/logos/power_logo.png'),
    fachada: require('../../images/fachadas/power_fachada.jpg'),
    link1: 'https://goo.gl/maps/vxQGzsKwfPNj4vKr6',
    link2: 'https://api.whatsapp.com/send?phone=5567981600028&text=Quero%20treinar%20durante%20minha%20viagem!%0AComo%20funciona%20o%20trabalho%20de%20voc%C3%AAs?',
    link3: 'https://www.instagram.com/power_fit_bonito_ms',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal11 = {
    name: 'isi tech',
    sobrenome: '',
    logo: require('../../images/logos/isi_tech_logo.png'),
    fachada: '',
    link1: '',
    link2: '',
    link3: 'https://instagram.com/isi.tecnologia',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeNormal12 = {
    name: 'Scooria Tattoo',
    sobrenome: '',
    logo: require('../../images/logos/scooria_logo.png'),
    fachada: require('../../images/fachadas/scooria_fachada.jpg'),
    link1: 'https://goo.gl/maps/4Ckwi4LCvcQSeJBs8',
    link2: 'https://www.bit.ly/duvidasscooriatattoo',
    link3: 'https://www.instagram.com/scooria_tattoo/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal13 = {
    name: 'Papelaria Multiflores',
    sobrenome: '',
    logo: require('../../images/logos/multiflores_logo.png'),
    fachada: require('../../images/fachadas/multiflores_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/5CPXg5r19cspF4G88',
    link2: 'https://wa.me/556732551357?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/papelariamultiflores/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal14 = {
    name: 'Dr. Lays Gonçalves Neto',
    sobrenome: '',
    logo: require('../../images/logos/lays_logo.png'),
    fachada: require('../../images/fachadas/lays_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/MEcPefX3wjiUQP5L6?g_st=ic',
    link2: 'https://api.whatsapp.com/message/YT2FSBGG5DHEJ1?autoload=1&app_absent=0',
    link3: 'https://instagram.com/dra.laysneto',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal15 = {
    name: 'Pub Paulista',
    sobrenome: '',
    logo: require('../../images/logos/pub_logo.png'),
    fachada: require('../../images/fachadas/pub_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/Q8djKn59zqTD4vVF9',
    link2: 'https://wa.me/message/UBBH6AGDNWH5J1',
    link3: 'https://instagram.com/pubpaulistabonitoms',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal16 = {
    name: 'Casarão',
    sobrenome: '',
    logo: require('../../images/logos/casarao_logo.png'),
    fachada: require('../../images/fachadas/casarao_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/6JF42Joc7cJ2FUbZ7',
    link2: 'https://wa.me/5567992848228?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/casaraodebonito/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal17 = {
    name: 'Açaí Concept',
    sobrenome: '',
    logo: require('../../images/logos/acai_logo.png'),
    fachada: require('../../images/fachadas/acai_fachada.jpg'),
    link1: 'https://goo.gl/maps/FaciyAR1aYcquEcK6',
    link2: 'https://wa.me/5567992293636?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/acaiconceptbonitoms/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal18 = {
    name: 'Quintal Pantaneiro',
    sobrenome: '',
    logo: require('../../images/logos/quintal_logo.png'),
    fachada: require('../../images/fachadas/quintal_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/X1QP9APZic9g1LGPA',
    link2: 'https://wa.me/5567998222004?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://instagram.com/quintalpantaneiro',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeNormal19 = {
    name: 'Sub Way',
    sobrenome: '',
    logo: require('../../images/logos/sub_way_logo.png'),
    fachada: require('../../images/fachadas/sub_way_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/y9oHxqAMY5YDqhYN8',
    link2: 'https://app.anota.ai/m/aD8KAHs9w',
    link3: 'https://www.instagram.com/subsbonito/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal20 = {
    name: 'Pizzaria San Marino',
    sobrenome: '',
    logo: require('../../images/logos/san_marino_logo.png'),
    fachada: require('../../images/fachadas/san_marino_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/cMSmZ7QMeNBXmXNd9',
    link2: 'https://wa.me/556732552656?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/pizzassanmarino/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal21 = {
name: 'Ponto Aroeira',
sobrenome: '',
    logo: require('../../images/logos/aroeira_logo.png'),
    fachada: require('../../images/fachadas/aroeira_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/EPUHvZbfkVwDJPkF9',
    link2: '',
    link3: 'https://instagram.com/postoaroeirabonito',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal22 = {
    name: 'Sabor da Casa',
    sobrenome: '',
    logo: require('../../images/logos/sabor_da_casa_logo.png'),
    fachada: require('../../images/fachadas/sabor_da_casa_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/zxGHFUx3kcPmovxe7',
    link2: 'https://pedido.anota.ai/loja/restaurante-sabor-da-casa?from=site&utm_source=portal-share-btn&referer=https%3A%2F%2Fl.instagram.com%2F',
    link3: 'https://www.instagram.com/sabordacasa.bto/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal23 = {
    name: 'Garden Coffee',
    sobrenome: '',
    logo: require('../../images/logos/garden_logo.png'),
    fachada: require('../../images/fachadas/garden_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/ywF3RxrSYkUWa2qQA',
    link2: 'https://wa.me/5567992874229?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/garden.bto/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal24 = {
    name: 'Lugano',
    sobrenome: '',
    logo: require('../../images/logos/lugano_logo.png'),
    fachada: require('../../images/fachadas/lugano_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/1Hy65jhXbkgmvdpX7?g_st=iwb',
    link2: 'https://tr.ee/R_y66hGEra',
    link3: 'https://instagram.com/chocolatelugano.bonito',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal25 = {
    name: 'Bonito Beer & Pizza',
    sobrenome: '',
    logo: require('../../images/logos/bonito_beer_logo.png'),
    fachada: require('../../images/fachadas/bonito_beer_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/VVzty5uNTGCEiXYA6',
    link2: 'https://wa.me/5567999943605?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/bonitobeer',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal26 = {
    name: 'Pousada Arte da Natureza',
    sobrenome: '',
    logo: require('../../images/logos/arte_da_natureza_logo.png'),
    fachada: require('../../images/fachadas/arte_da_natureza_fachada.jpg'),
    link1: 'https://goo.gl/maps/nBDzVgKHRj8aBVZcA',
    link2: 'https://pousadaartedanatureza.com.br',
    link3: 'https://www.instagram.com/pousadaartedanatureza/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal27 = {
    name: 'Pousada Galeria Artes',
    sobrenome: '',
    logo: require('../../images/logos/pousada_galeria_logo.png'),
    fachada: require('../../images/fachadas/pousada_galeria_fachada.jpg'),
    link1: 'https://goo.gl/maps/HJmvZVMJMKqzMoB6A',
    link2: 'https://wa.me/5567992539311?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/pousadagaleriaartes',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal28 = {
    name: 'Ypê Flats',
    sobrenome: '',
    logo: require('../../images/logos/ype_flats_logo.png'),
    fachada: require('../../images/fachadas/ype_flats_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/3Y4FZanZAxLKRceP8',
    link2: 'https://wa.me/5567992431101?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/ypeflat',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal29 = {
    name: 'Hotel Paraíso das Águas',
    sobrenome: '',
    logo: require('../../images/logos/paraiso_logo.png'),
    fachada: require('../../images/fachadas/paraiso_fachada.jpg'),
    link1: 'https://goo.gl/maps/CJR7aZ1ABXfKW8rw7',
    link2: 'https://www.paguas.com.br/',
    link3: 'https://www.instagram.com/hotelpaguas',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal30 = {
    name: 'Camila Boutique',
    sobrenome: '',
    logo: require('../../images/logos/camila_logo.png'),
    fachada: require('../../images/fachadas/camila_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/FWzrj6LdB6mSXgaq8',
    link2: ' https://wa.me/5567992848228?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://instagram.com/camillaboutique_oficial',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeNormal31 = {
    name: 'Havaianas',
    sobrenome: '',
    logo: require('../../images/logos/havaianas_logo.png'),
    fachada: require('../../images/fachadas/havaianas_fachada.jpg'),
    link1: 'https://goo.gl/maps/BXKmR1GzjEj7wi4Y8',
    link2: 'https://wa.me/5567999072828?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/havaianas/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const curandeira = {
    name: 'Galeria Curandeira',
    sobrenome: '',
    logo: require('../../images/logos/soul_bonita_logo.png'),
    fachada: require('../../images/fachadas/curandeira_fachada.jpg'),
    link1: 'https://goo.gl/maps/RMH7ibeHRjZ9GgPm6',
    link2: 'https://wa.me/message/KTJIUEMYJUUOE1',
    link3: 'https://www.instagram.com/galeria.curandera?igsh=MzRlODBiNWFlZA==',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const allegra = {
    name: 'Allegra Gelato & Café ',
    sobrenome: '',
    logo: require('../../images/logos/soul_bonita_logo.png'),
    fachada: require('../../images/fachadas/allegra_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/PueZognyGJrs1QD99',
    link2: 'https://menu.ifood.com.br/63fd1594-4a07-429d-8929-bde384f3bce9/catalog',
    link3: 'https://www.instagram.com/allegragelato/?igsh=MWtiamkyOHZ6YnFwbQ%3D%3D',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const acquaWash = {
    name: 'Acqua Wash',
    sobrenome: 'Lavanderia Express',
    logo: require('../../images/logos/acquaWash_logo.png'),
    fachada: require('../../images/fachadas/acquaWash_fachada.png'),
    link1: 'https://maps.app.goo.gl/kTbD2cbLc6Cd4dBh8',
    link2: 'https://www.acquawash.com.br',
    link3: 'https://www.instagram.com/acquawash.lavanderia',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const dib = {
    name: 'DIB',
    sobrenome: 'Transfer e Turismo',
    logo: require('../../images/logos/dib_logo.png'),
    fachada: require('../../images/fachadas/dib_fachada.png'),
    link1: 'https://maps.app.goo.gl/HmrB97Aw17am5hRr7',
    link2: 'https://wa.me/5567984123613',
    link3: 'https://www.instagram.com/dib_transfer_e_turismo',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeNormal32 = {
    name: 'Avatim',
    sobrenome: '',
    logo: require('../../images/logos/avatim_logo.png'),
    fachada: require('../../images/fachadas/avatim_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/NCyDKrvCVWabqEHEA',
    link2: 'https://wa.me/5567991251551?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://instagram.com/lojaavatimbonito?igshid=MWZjMTM2ODFkZg==',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeNormal33 = {
    name: 'Studio Muriqui',
    sobrenome: '',
    logo: require('../../images/logos/muriqui_logo.png'),
    fachada: require('../../images/fachadas/muriqui_fachada.jpg'),
    link1: 'https://goo.gl/maps/koYQcN5KusoNom579',
    link2: 'https://www.studiomuriqui.art.br',
    link3: 'https://www.instagram.com/studiomuriqui/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeNormal34 = {
    name: 'Suellén Becker',
    sobrenome: '',
    logo: require('../../images/logos/suellen_logo.png'),
    fachada: require('../../images/fachadas/suellen_fachada.jpg'),
    link1: 'https://goo.gl/maps/k716VvfwYUhb6cTY6',
    link2: 'https://wa.me/5567992252902?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/suelenbeckercamiseteria/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

// export const cidadeNormal35 = {
//     name: 'Soul Bonita | Mãos do Cerrado',
//     sobrenome: '',
//     logo: require('../../images/logos/soul_bonita_logo.png'),
//     fachada: require('../../images/fachadas/soul_bonita_fachada.jpg'),
//     link1: 'https://maps.app.goo.gl/zopx8TR2ApbG3VuQA',
//     link2: 'http://www.abaetecotur.com.br/',
//     link3: 'https://www.instagram.com/soulbonitams/',
//     iconButton1: require('../../images/icons/maps1.png'),
//     iconButton2: require('../../images/icons/whats1.png'),
//     iconButton3: require('../../images/icons/insta1.png'),
// }

export const cidadeMaster36 = {
    name: 'Roteiro Bonito',
    sobrenome: '',
    logo: require('../../images/logos/roteiro_logo.png'),
    fachada: require('../../images/fachadas/roteiro_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/dpb74SB9GWjVNeQ2A?g_st=iwb',
    link2: 'https://wa.me/5567993087534?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/roteirobonitoms/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const cidadeMaster37 = {
    name: 'Echoes Tour',
    sobrenome: '',
    logo: require('../../images/logos/echoes_logo.png'),
    fachada: require('../../images/fachadas/echoes_fachada.jpg'),
    link1: 'https://goo.gl/maps/DcowoCbUi3XXmQFA7',
    link2: 'https://wa.me/5567984783517?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/echoestour/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster38 = {
    name: 'Acqua Studio de Viagens',
    sobrenome: '',
    logo: require('../../images/logos/acqua_logo.png'),
    fachada: require('../../images/fachadas/acqua_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/xfNcTENDdZudyDvq5',
    link2: 'https://www.acquaviagens.com.br',
    link3: 'https://instagram.com/acquaviagens',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster39 = {
    name: 'Agência Galeria Artes',
    sobrenome: '',
    logo: require('../../images/logos/agencia_galeria_logo.png'),
    fachada: require('../../images/fachadas/agencia_galeria_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/43VGy5kWdzxGZbZ27',
    link2: 'https://wa.me/5567999510089?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/agenciagaleriaartes/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster40 = {
    name: 'Chácara Beija-flor',
    sobrenome: '',
    logo: require('../../images/logos/beija_flor_logo.png'),
    fachada: require('../../images/fachadas/beija_flor_fachada.jpg'),
    link1: 'https://goo.gl/maps/62kKvkkDnWwhQ6Uo9',
    link2: 'https://wa.me/5567984124844?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/chacarabeijaflorbonito/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster41 = {
    name: 'Agência Impacto',
    sobrenome: '',
    logo: require('../../images/logos/impacto_logo.png'),
    fachada: require('../../images/fachadas/impacto_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/wuTu28WZyJcFnQ9a9',
    link2: 'http://wa.me/message/YZSWJWXWEXBWM1',
    link3: 'https://instagram.com/impactoecoturismo',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster42 = {
    name: 'Bonito Eco Tour',
    sobrenome: '',
    logo: require('../../images/logos/bonito_eco_tour_logo.png'),
    fachada: require('../../images/fachadas/bonito_eco_tour_fachada.jpg'),
    link1: 'https://g.page/ecotour-passeios-em-bonito?share',
    link2: 'https://bonitoecotour.com/ ',
    link3: 'https://instagram.com/bonitoecotouroficial',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster43 = {
    name: 'Tô de Férias',
    sobrenome: '',
    logo: require('../../images/logos/to_de_ferias_logo.png'),
    fachada: require('../../images/fachadas/to_de_ferias_fachada.jpg'),
    link1: 'https://goo.gl/maps/aFZwZDAv8sFpTuXPA',
    link2: 'https://wa.me/556791394413?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/todeferiasembonito/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster44 = {
    name: 'Bonito Brasil',
    sobrenome: '',
    logo: require('../../images/logos/bonito_brasil_logo.png'),
    fachada: require('../../images/fachadas/bonito_brasil_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/8Sfsc8M4uRXDb2se7',
    link2: '',
    link3: 'https://www.instagram.com/bonitobrasiltour/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster45 = {
    name: 'G2 Esportes',
    sobrenome: '',
    logo: require('../../images/logos/g2_logo.png'),
    fachada: require('../../images/fachadas/g2_fachada.jpg'),
    link1: '',
    link2: 'https://wa.me/5567991006541?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/g2esportes_/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}
export const cidadeMaster46 = {
    name: 'Khárís Tur',
    sobrenome: '',
    logo: require('../../images/logos/kharis_logo.png'),
    fachada: require('../../images/fachadas/kharis_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/bAZq32ug2VbNd2ht9',
    link2: 'https://wa.me/5567992258077?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: 'https://www.instagram.com/kharistur/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}


export const atrativosGold01 = {
    name: 'Refúgio da Barra',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/refugio_da_barra_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/sKYpaHESRShjegTC8',
    link2: '',
    link3: 'https://www.instagram.com/refugiodabarra/',
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const atrativosGold02 = {
    name: 'Bonito Scuba',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/bonito_scuba_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/hr6BjJE89wSAf15YA',
    link2: '',
    link3: "https://www.instagram.com/bonitoscuba/",
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}


export const atrativosGold03 = {
    name: 'Estrela do Formoso',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/estrela_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/vVYzHE4nbsmtbx9c8',
    link2: 'https://www.estreladoformoso.com.br',
    link3: "https://instagram.com/estreladoformoso",
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const atrativosGold04 = {
    name: 'Balneário do Sol',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/balneario_do_sol_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/gFiSoJdKUDKaiLAY8',
    link2: 'https://www.balneariodosol.com.br/',
    link3: "https://www.instagram.com/balneariodosol",
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const atrativosGold05 = {
    name: 'Eco Park Porto da Ilha',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/porto_da_ilha_fachada.jpg'),
    link1: 'https://bit.ly/ecoparkportodailha',
    link2: 'https://wa.me/5567984665878?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: "https://www.instagram.com/ecoparkportodailha",
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}


export const atrativosGold06 = {
    name: 'Recanto Zeus',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/recanto_zeus_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/dpfj9KSd7WcBb1A46',
    link2: 'https://wa.me/5546999023110?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: "https://www.instagram.com/recanto_zeus",
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const atrativosGold07 = {
    name: 'O Guardado | Lendas & Causos do Pantanal',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/o_guardado_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/27p75WcU5X7dMP3F7',
    link2: 'https://wa.me/5567992301652?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: "https://www.instagram.com/oguardadobonitoms",
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const atrativosGold08 = {
    name: 'Nascente Azul',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/nascente_azul_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/fh5cokxfLFWKb4BHA',
    link2: 'https://nascenteazul.com.br/',
    link3: "https://www.instagram.com/nascenteazul",
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/site1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}

export const atrativosGold09 = {
    name: 'Camping Rio Formoso',
    sobrenome: '',
    logo: require('../../images/logos/bacuri_logo.png'),
    fachada: require('../../images/fachadas/camping_rio_formoso_fachada.jpg'),
    link1: 'https://maps.app.goo.gl/yUm39FMvp6jjFGk5A',
    link2: 'https://wa.me/5567992845994?text=Ol%C3%A1%2C+cheguei+aqui+pelo+QR+Maps.+%3A%29',
    link3: "https://www.instagram.com/campingrioformosoebalneario/",
    iconButton1: require('../../images/icons/maps1.png'),
    iconButton2: require('../../images/icons/whats1.png'),
    iconButton3: require('../../images/icons/insta1.png'),
}



