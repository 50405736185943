import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Master from './pages/master';
import CidadeGold01 from './pages/cidade-gold-01';
import CidadeGold02 from './pages/cidade-gold-02';
import CidadeGold03 from './pages/cidade-gold-03';
import CidadeGold04 from './pages/cidade-gold-04';
import CidadeGold05 from './pages/cidade-gold-05';
import CidadeGold06 from './pages/cidade-gold-06';
import CidadeGold07 from './pages/cidade-gold-07';
import CidadeNormal08 from './pages/cidade-normal-08';
import CidadeNormal09 from './pages/cidade-normal-09';
import CidadeNormal10 from './pages/cidade-normal-10';
import CidadeNormal11 from './pages/cidade-normal-11';
import CidadeNormal12 from './pages/cidade-normal-12';
import CidadeNormal13 from './pages/cidade-normal-13';
import CidadeNormal14 from './pages/cidade-normal-14';
import CidadeNormal15 from './pages/cidade-normal-15';
import CidadeNormal16 from './pages/cidade-normal-16';
import CidadeNormal17 from './pages/cidade-normal-17';
import CidadeNormal18 from './pages/cidade-normal-18';
import CidadeNormal19 from './pages/cidade-normal-19';
import CidadeNormal20 from './pages/cidade-normal-20';
import CidadeNormal21 from './pages/cidade-normal-21';
import CidadeNormal22 from './pages/cidade-normal-22';
import CidadeNormal23 from './pages/cidade-normal-23';
import CidadeNormal24 from './pages/cidade-normal-24';
import CidadeNormal25 from './pages/cidade-normal-25';
import CidadeNormal26 from './pages/cidade-normal-26';
import CidadeNormal27 from './pages/cidade-normal-27';
import CidadeNormal28 from './pages/cidade-normal-28';
import CidadeNormal29 from './pages/cidade-normal-29';
import CidadeNormal30 from './pages/cidade-normal-30';
import CidadeNormal31 from './pages/cidade-normal-31';
import CidadeNormal32 from './pages/cidade-normal-32';
import CidadeNormal33 from './pages/cidade-normal-33';
import CidadeNormal34 from './pages/cidade-normal-34';
// import CidadeNormal35 from './pages/cidade-normal-35';
import Curandeira from './pages/curandeira';
import Allegra from './pages/allegra';
import AcquaWash from './pages/acquaWash';
import Dib from './pages/dib';
import CidadeMaster36 from './pages/cidade-master-36';
import CidadeMaster37 from './pages/cidade-master-37';
import CidadeMaster38 from './pages/cidade-master-38';
import CidadeMaster39 from './pages/cidade-master-39';
import CidadeMaster40 from './pages/cidade-master-40';
import CidadeMaster41 from './pages/cidade-master-41';
import CidadeMaster42 from './pages/cidade-master-42';
import CidadeMaster43 from './pages/cidade-master-43';
import CidadeMaster44 from './pages/cidade-master-44';
import CidadeMaster45 from './pages/cidade-master-45';
import CidadeMaster46 from './pages/cidade-master-46';
import AtrativosGold01 from './pages/atrativos-gold-01';
import AtrativosGold02 from './pages/atrativos-gold-02';
import AtrativosGold03 from './pages/atrativos-gold-03';
import AtrativosGold04 from './pages/atrativos-gold-04';
import AtrativosGold05 from './pages/atrativos-gold-05';
import AtrativosGold06 from './pages/atrativos-gold-06';
import AtrativosGold07 from './pages/cidade-alpha-guardado';
import AtrativosGold08 from './pages/atrativos-gold-08';
import AtrativosGold09 from './pages/atrativos-gold-09';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Master />}></ Route>
        <Route path='/master' element={<Master />}></ Route>
        <Route path='/cidade-gold-01' element={<CidadeGold01 />}></ Route>
        <Route path='/cidade-gold-02' element={<CidadeGold02 />}></ Route>
        <Route path='/cidade-gold-03' element={<CidadeGold03 />}></ Route>
        <Route path='/cidade-gold-04' element={<CidadeGold04 />}></ Route>
        <Route path='/cidade-gold-05' element={<CidadeGold05 />}></ Route>
        <Route path='/cidade-gold-06' element={<CidadeGold06 />}></ Route>
        <Route path='/cidade-gold-07' element={<CidadeGold07 />}></ Route>
        <Route path='/cidade-normal-08' element={<CidadeNormal08 />}></ Route>
        <Route path='/cidade-normal-09' element={<CidadeNormal09 />}></ Route>
        <Route path='/cidade-normal-10' element={<CidadeNormal10 />}></ Route>
        <Route path='/alpha-isitech' element={<CidadeNormal11 />}></ Route>
        <Route path='/cidade-normal-12' element={<CidadeNormal12 />}></ Route>
        <Route path='/cidade-normal-13' element={<CidadeNormal13 />}></ Route>
        <Route path='/cidade-normal-14' element={<CidadeNormal14 />}></ Route>
        <Route path='/cidade-normal-15' element={<CidadeNormal15 />}></ Route>
        <Route path='/cidade-normal-16' element={<CidadeNormal16 />}></ Route>
        <Route path='/cidade-normal-17' element={<CidadeNormal17 />}></ Route>
        <Route path='/cidade-normal-18' element={<CidadeNormal18 />}></ Route>
        <Route path='/cidade-normal-19' element={<CidadeNormal19 />}></ Route>
        <Route path='/cidade-normal-20' element={<CidadeNormal20 />}></ Route>
        <Route path='/cidade-normal-21' element={<CidadeNormal21 />}></ Route>
        <Route path='/cidade-normal-22' element={<CidadeNormal22 />}></ Route>
        <Route path='/cidade-normal-23' element={<CidadeNormal23 />}></ Route>
        <Route path='/cidade-normal-24' element={<CidadeNormal24 />}></ Route>
        <Route path='/cidade-normal-25' element={<CidadeNormal25 />}></ Route>
        <Route path='/cidade-normal-26' element={<CidadeNormal26 />}></ Route>
        <Route path='/cidade-normal-27' element={<CidadeNormal27 />}></ Route>
        <Route path='/cidade-normal-28' element={<CidadeNormal28 />}></ Route>
        <Route path='/cidade-normal-29' element={<CidadeNormal29 />}></ Route>
        <Route path='/cidade-normal-30' element={<CidadeNormal30 />}></ Route>
        <Route path='/cidade-normal-31' element={<CidadeNormal31 />}></ Route>
        <Route path='/cidade-normal-32' element={<CidadeNormal32 />}></ Route>
        <Route path='/cidade-normal-33' element={<CidadeNormal33 />}></ Route>
        <Route path='/cidade-normal-34' element={<CidadeNormal34 />}></ Route>
        {/* <Route path='/cidade-normal-35' element={<CidadeNormal35 />}></ Route> */}
        <Route path='/curandeira' element={<Curandeira />}></ Route>
        <Route path='/allegra' element={<Allegra />}></ Route>
        <Route path='/acquaWash' element={<AcquaWash />}></ Route>
        <Route path='/dib' element={<Dib />}></ Route>
        <Route path='/cidade-master-36' element={<CidadeMaster36 />}></ Route>
        <Route path='/cidade-master-37' element={<CidadeMaster37 />}></ Route>
        <Route path='/cidade-master-38' element={<CidadeMaster38 />}></ Route>
        <Route path='/cidade-master-39' element={<CidadeMaster39 />}></ Route>
        <Route path='/cidade-master-40' element={<CidadeMaster40 />}></ Route>
        <Route path='/cidade-master-41' element={<CidadeMaster41 />}></ Route>
        <Route path='/cidade-master-42' element={<CidadeMaster42 />}></ Route>
        <Route path='/cidade-master-43' element={<CidadeMaster43 />}></ Route>
        <Route path='/cidade-master-44' element={<CidadeMaster44 />}></ Route>
        <Route path='/cidade-master-45' element={<CidadeMaster45 />}></ Route>
        <Route path='/cidade-master-46' element={<CidadeMaster46 />}></ Route>
        <Route path='/atrativos-gold-01' element={<AtrativosGold01 />}></ Route>
        <Route path='/atrativos-gold-02' element={<AtrativosGold02 />}></ Route>
        <Route path='/atrativos-gold-03' element={<AtrativosGold03 />}></ Route>
        <Route path='/atrativos-gold-04' element={<AtrativosGold04 />}></ Route>
        <Route path='/atrativos-gold-05' element={<AtrativosGold05 />}></ Route>
        <Route path='/atrativos-gold-06' element={<AtrativosGold06 />}></ Route>
        <Route path='/cidade-alpha-guardado' element={<AtrativosGold07 />}></ Route>
        <Route path='/atrativos-gold-08' element={<AtrativosGold08 />}></ Route>
        <Route path='/atrativos-gold-09' element={<AtrativosGold09 />}></ Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
